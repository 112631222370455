/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 */

 @font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/Lato-Regular.ttf");
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 200;
  src: url("/assets/Lato-Light.ttf");
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  src: url("/assets/Lato-Bold.ttf");
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: url("/assets/Lato-Black.ttf");
}
